import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useStaticQuery, graphql } from 'gatsby'
import GoogleMapReact from 'google-map-react'
import {FaEnvelope, FaPhone, FaMapMarkerAlt, FaTwitter} from 'react-icons/fa'

const ContactPage = () => {
  const data = useStaticQuery(graphql`
  query {
  contact: markdownRemark (frontmatter: {templateKey: {eq: "contact-page"}}) {
    frontmatter{
      title
      subtitle
      map
      contact_email
      contact_phone
      contact_address
    }
    html
  } 
}
`)

const mapCoords = data.contact.frontmatter.map.split(',')
//console.log(mapCoords)
 
  const mapProps = {
    center: {
      lat: parseFloat(mapCoords[0]),
      lng: parseFloat(mapCoords[1])
    },
    zoom: 17
  }
  const mapMarkerStyle = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    fontSize: '4em',
    color: '#235175'
  }
  return (
    <Layout>
      <SEO title="Contact"/>
      <section className="section contact">
      <h1>{data.contact.frontmatter.title}</h1>
      
      <div className="columns">
      <div className="column is-half contact-map" style={{ height: '550px'}}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyD1NPNV4HHss2yGQdqOpHLgvQGXXysT5OM' }}
          defaultCenter={mapProps.center}
          defaultZoom={mapProps.zoom}
        >
          <FaMapMarkerAlt style={mapMarkerStyle} lat={mapCoords[0]} lng={mapCoords[1]} text="Shepperd Fox"/>
        </GoogleMapReact>
      </div>
      <div className="column is-half contact-text">
      <p className="subtitle">{data.contact.frontmatter.subtitle}</p>
        <div>
          <FaEnvelope />
          <div><a href={`mailto:${data.contact.frontmatter.contact_email}`}>{data.contact.frontmatter.contact_email}</a></div>
        </div>
        <div>
          <FaPhone />
          <div><a href={`tel:${data.contact.frontmatter.contact_phone}`}>{data.contact.frontmatter.contact_phone}</a></div>
        </div>
        <div>
          <FaMapMarkerAlt />
          <div>
          {
              //nl2br react replacement https://medium.com/@kevinsimper/react-newline-to-break-nl2br-a1c240ba746
              data.contact.frontmatter.contact_address.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>
              })
          }
          </div>
        </div>
      </div>
      </div>
      <div class="contact-body columns">
        <div dangerouslySetInnerHTML={{ __html: data.contact.html}} className="column is-half"></div>
      </div>
      </section>

    </Layout>
  )
}

export default ContactPage

